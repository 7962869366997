module.exports = {
  outside: {
    x: 0.85,
    y: 0.23,
    z: 1,
    str: 1.4,
    ox: 0.5,
    oy: 0.5,
    scale: 0.97,
    text: 'World Tour',
    font: 34,
    lx: 0.1,
    ly: 0.3,
    project: 'tour',
  },

  room: {
    x: 0.5,
    y: 0.5,
    z: 2,
    str: 1,
    ox: 0.5,
    oy: 0.5,
    scale: 0.97,
  },
  cover: {
    x: 0.765,
    y: -0.09,
    z: 2.1,
    str: 0.85,
    ox: 0,
    oy: 0,
    scale: 1,
  },
  bed: {
    x: -0.015,
    y: 0.3,
    z: 3,
    str: 0.9,
    ox: 0,
    oy: 0,
  },
  ipod: {
    x: 0.228,
    y: 0.421,
    z: 3.2,
    str: 0.85,
    scale: 1.1,
    text: 'Listen',
    ly: -0.5,
    font: 30,
  },
  daki: {
    x: 0.12,
    y: 0.47,
    z: 4,
    str: 0.85,
    scale: 0.9,
  },
  animol: {
    x: 0.14,
    y: 0.53,
    z: 4.1,
    str: 0.8,
    scale: 0.86,
    text: 'Animol',
    font: 40,
    ly: -0.6,
  },
  balloon2: {
    x: 0.09,
    y: 0.6,
    z: 4.2,
    str: 0.4,
    ox: 0,
    oy: 0,
    scale: 0.8,
  },
  counter: {
    x: 0.08,
    y: 0.8,
    z: 4.3,
    str: 0.5,
    ox: 0,
    oy: 0,
  },
  rice: {
    x: 0.407,
    y: 0.336,
    z: 4.4,
    str: 0.83,
    ox: 0,
    oy: 0,
    scale: 1,
  },
  balloon1: {
    x: 0.3,
    y: 0.08,
    z: 4.5,
    str: 0.75,
    ox: 0,
    oy: 0,
    scale: 0.75,
  },
  painting: {
    x: 0.37,
    y: 0.43,
    z: 4.6,
    str: 0.9,
    // ox: 0,
    // oy: 0,
    scale: 0.75,
    text: 'Drawing Board',
    font: 40,
    project: 'mural',
  },
  lantern: {
    x: 0.13,
    y: 0.8,
    z: 5,
    str: 0.4,
    ox: 0,
    oy: 0,
    lx: 0.5,
    ly: 0.5,
    scale: 1,
    // text: 'Lights Off',
    // font: 20,
  },
  shelf: {
    x: 0.484,
    y: 0.01,
    z: 5.1,
    str: 0.9,
    ox: 0,
    oy: 0,
    scale: 0.9,
  },
  spider: {
    x: 0.65,
    y: 0.005,
    z: 5.2,
    str: 3,
    ox: 0,
    oy: 0,
    scale: 0.76,
  },
  decors: {
    x: 0.496,
    y: 0.085,
    z: 5.3,
    str: 0.85,
    ox: 0,
    oy: 0,
    scale: 0.9,
  },
  gifts: {
    x: 0.56,
    y: 0.53,
    z: 5.4,
    str: 0.8,
    scale: 0.9,
    text: 'Birthday Gifts',
    font: 40,
    project: 'fanarts',
  },
  shelftop: {
    x: 0.484,
    y: 0.01,
    z: 5.9,
    str: 0.9,
    ox: 0,
    oy: 0,
    scale: 0.9,
  },
  bowls: {
    x: 0.507,
    y: 0.325,
    z: 6,
    str: 0.8,
    ox: 0,
    oy: 0,
    scale: 0.8,
  },
  bowls2: {
    texture: 'bowls',
    x: 0.6,
    y: 0.25,
    z: 6,
    str: 0.8,
    ox: 0,
    oy: 0,
    scale: 0.8,
  },
  trunk: {
    x: 0.686,
    y: -0.085,
    z: 6,
    str: 1,
    ox: 0,
    oy: 0,
    scale: 0.97,
  },
  banner: {
    x: 0.887,
    y: 0.135,
    z: 7,
    str: 0.85,
    ox: 0.5,
    oy: 0.5,
    scale: 0.85,
  },
  letters: {
    x: 0.83,
    y: 0.72,
    z: 7,
    str: 0.85,
    ox: 0.5,
    oy: 0.5,
    scale: 1,
    text: 'Birthday Messages',
    font: 40,
    project: 'messages',
  },
  flag1: {
    x: -0.02,
    y: -0.01,
    z: 8,
    ox: 0,
    oy: 0,
    str: 0.4,
    scale: 0.8,
  },
  flag2: {
    x: 0.15,
    y: -0.005,
    z: 9,
    ox: 0.05,
    oy: 0,
    lx: 0.58,
    ly: 0.27,
    str: 0.6,
    scale: 0.8,
  },
  flag3: {
    x: 0.4,
    y: -0.05,
    z: 10,
    ox: 0,
    oy: 0,
    str: 0.7,
    scale: 0.8,
  },

  fauna: {
    x: 0.66,
    y: 0.54,
    z: 91,
    str: 0.8,
    scale: 0.84,
  },
  kronii: {
    x: 0.555,
    y: 0.789,
    z: 92,
    str: -0.1,
    scale: 0.9,
  },
  mumei: {
    x: 0.495,
    y: 0.75,
    z: 93,
    ox: 0.5,
    oy: 0.7,
    str: 0,
    scale: 0.87,
    lx: 0,
    ly: -0.56,
  },
  sana: {
    x: 0.34,
    y: 0.82,
    z: 94,
    str: -0.15,
    scale: 0.87,
  },
  bae: {
    x: 0.1,
    y: -0.005,
    z: 95,
    ox: 0.05,
    oy: 0,
    lx: 0.58,
    ly: 0.27,
    str: 0.4,
    scale: 0.8,
  },

  table: {
    x: 0.5,
    y: 0.98,
    z: 100,
    str: -0.3,
    scale: 0.97,
  },
  cake: {
    x: 0.495,
    y: 0.83,
    z: 100.1,
    str: -0.33,
    scale: 0.97,
    text: 'Make a wish! Blow the candle!',
    font: 30,
  },

  front1: {
    x: 0.1,
    y: 0.67,
    z: 101,
    str: -0.4,
    ox: 0.5,
    oy: 0.5,
  },
  front2: {
    x: 1.002,
    y: 1.001,
    z: 103,
    str: 0.4,
    ox: 1,
    oy: 1,
    scale: 0.8,
  },
  front3: {
    x: -0.005,
    y: 0.3,
    z: 102,
    str: -0.5,
    ox: 0,
    oy: 0.5,
    scale: 0.6,
  },
};

module.exports = {
  abstraction: {
    sprite: 'hoomans',
    frame: 'abstraction',
    x: 0.254,
    y: 0.595,
    z: 15,
    str: 0.7,
    scale: 0.9,
    name: 'Abstraction',
  },
  bukiyos: {
    sprite: 'hoomans',
    frame: 'bukiyos',
    x: 0.292,
    y: 0.450,
    z: 15.1,
    str: 0.9,
    scale: 0.9,
    name: 'Mikki BUKIYOS',
  },
  dramon: {
    sprite: 'hoomans',
    frame: 'dramon',
    x: 0.789,
    y: 0.588,
    z: 15.2,
    str: 0.85,
    scale: 0.9,
  },
  eli: {
    sprite: 'hoomans',
    frame: 'eli',
    x: 0.72,
    y: 0.83,
    z: 15.3,
    str: 0.81,
    scale: 0.9,
  },
  faerieko: {
    sprite: 'hoomans',
    frame: 'faerieko',
    x: 0.072,
    y: 0.186,
    z: 103.1,
    str: -0.5,
    scale: 0.75,
    name: 'Faerieko',
  },
  jackiechan: {
    sprite: 'hoomans',
    frame: 'jackiechan',
    x: 0.843,
    y: 0.6,
    z: 15.5,
    str: 0.85,
    scale: 0.9,
    name: 'Jackie Chan',
  },
  jamie: {
    sprite: 'hoomans',
    frame: 'jamie',
    x: 0.848,
    y: 0.93,
    z: 103.2,
    str: 0.4,
    scale: 0.9,
    name: 'AlphaCas',
  },
  jetrico: {
    sprite: 'hoomans',
    frame: 'jetrico',
    x: 0.417,
    y: 0.75,
    z: 15.7,
    str: 0.75,
    scale: 0.9,
  },
  mogumogu: {
    sprite: 'hoomans',
    frame: 'mogumogu',
    x: 0.235,
    y: 0.924,
    z: 15.8,
    str: 0.2,
    scale: 0.9,
    name: 'Mogu Mogu',
  },
  nintan: {
    sprite: 'hoomans',
    frame: 'nintan',
    x: 0.66,
    y: 0.88,
    z: 15.9,
    str: 0.85,
    scale: 0.9,
    name: 'Nin-tan',
  },
  shoujobirb: {
    sprite: 'hoomans',
    frame: 'shoujobirb',
    x: 0.962,
    y: 0.295,
    z: 16,
    str: 0.85,
    scale: 0.9,
  },
  souuup: {
    sprite: 'hoomans',
    frame: 'souuup',
    x: 0.95,
    y: 0.885,
    z: 103.3,
    str: 0.4,
    scale: 0.9,
  },
  sprub: {
    sprite: 'hoomans',
    frame: 'sprub',
    x: 0.271,
    y: 0.718,
    z: 3.9,
    str: 0.75,
    scale: 0.9,
  },
  trixmix: {
    sprite: 'hoomans',
    frame: 'trixmix',
    x: 0.333,
    y: 0.571,
    z: 16.3,
    str: 0.7,
    scale: 0.9,
    name: 'TrixMix',
  },
  wowanator: {
    sprite: 'hoomans',
    frame: 'wowanator',
    x: 0.62,
    y: 0.623,
    z: 91.1,
    str: 0.7,
    scale: 0.9,
  },
};
